@import url(https://fonts.googleapis.com/css?family=Major+Mono+Display);
@import url(https://fonts.googleapis.com/css?family=Inconsolata);

html {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  margin: 0 auto;
  width: 100%;
  min-height: -webkit-fill-available;
}

body {
  font-family: 'Inconsolata', monospace;
  width: 100vw;
}

h1, h2, h3, h4, h5, h6, nav {
    font-family: "Major Mono Display", monospace;
    text-transform: lowercase;
}

#navb {
  max-height: 9vh;
  min-height: 2em;
  width: 100vw;
}

.navbar-toggler-icon {
  top: 0;
  width: 0.75em;
  height: 0.75em;
}

#responsive-navbar-nav{
  background-color: white;
  width: 100vw;
  z-index: 1;
}

.landing {
  /* Photo by Rodion Kutsaev on Unsplash */
  background-image: url(../images/rodion-kutsaev-unsplash1.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #c6cbef;
  height: 85vh;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Major Mono Display", monospace;
}

.full-page-container {
  min-height: 70vh;
  max-width: 95vw;
}

.section-container {
  max-width: 70%;
  margin: 5% auto;
}

.portfolio-container {
  margin: auto;
  max-width: 80%;
}

.portfolio-container > img {
  width: 100%;
}

/* .ghostDiv {
  display: block;
  padding: 20px;
  min-height: 85vh;
  width: 100%;
} */

footer {
  width: 100%;
  text-align: center;
}

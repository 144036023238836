@import url(https://fonts.googleapis.com/css?family=Major+Mono+Display);
@import url(https://fonts.googleapis.com/css?family=Inconsolata);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* .page {
  position: absolute; */
  /* top: 100px; */
  /* left: 0;
  right: 0;
} */

/* simple - enter transition 800ms, exit 200ms */
.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    -webkit-transition: opacity 800ms linear 200ms;
    transition: opacity 800ms linear 200ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    -webkit-transition: opacity 200ms linear;
    transition: opacity 200ms linear;
}

html {
  height: 100vh;
  height: calc(1vh * 100);
  height: calc(var(--vh, 1vh) * 100);
  margin: 0 auto;
  width: 100%;
  min-height: -webkit-fill-available;
}

body {
  font-family: 'Inconsolata', monospace;
  width: 100vw;
}

h1, h2, h3, h4, h5, h6, nav {
    font-family: "Major Mono Display", monospace;
    text-transform: lowercase;
}

#navb {
  max-height: 9vh;
  min-height: 2em;
  width: 100vw;
}

.navbar-toggler-icon {
  top: 0;
  width: 0.75em;
  height: 0.75em;
}

#responsive-navbar-nav{
  background-color: white;
  width: 100vw;
  z-index: 1;
}

.landing {
  /* Photo by Rodion Kutsaev on Unsplash */
  background-image: url(/static/media/rodion-kutsaev-unsplash1.e2a2718a.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #c6cbef;
  height: 85vh;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Major Mono Display", monospace;
}

.full-page-container {
  min-height: 70vh;
  max-width: 95vw;
}

.section-container {
  max-width: 70%;
  margin: 5% auto;
}

.portfolio-container {
  margin: auto;
  max-width: 80%;
}

.portfolio-container > img {
  width: 100%;
}

/* .ghostDiv {
  display: block;
  padding: 20px;
  min-height: 85vh;
  width: 100%;
} */

footer {
  width: 100%;
  text-align: center;
}

